import React from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";

const ProductNavigation = ({
  productName,
  featuresLink,
  techSpecsLink,
  buyLink,
  faqLink,
}) => {
  return (
    <>
      <nav>
        <Popover className="relative sticky top-0 z-20 md:hidden">
          {({ open }) => (
            <>
              <div className="h-[56px] w-full bg-gray-800 px-6 flex flex-row items-center justify-between">
                <h3 className=" flex-grow text-lg font-medium text-white">
                  <Link to={featuresLink}>{productName}</Link>
                </h3>
                <Popover.Button>
                  <ChevronDownIcon
                    className={`${
                      open
                        ? "h-6 w-6 text-white transform rotate-180 mr-3"
                        : "h-6 w-6 text-white mr-3"
                    }`}
                  />
                </Popover.Button>
                <Link to={buyLink}>
                  <button className="py-2 px-3 rounded-full bg-blue-600 text-xs text-white">
                    Buy
                  </button>
                </Link>
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-0 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-0 opacity-0"
              >
                <Popover.Panel>
                  <div className="w-full bg-gray-700 absolute ">
                    <ul className="flex flex-col divide-y divide-white pt-6 pb-8 px-10">
                      <li className="text-white text-base font-light py-3">
                        <Link
                          activeClassName="text-blue-500 font-medium"
                          to={featuresLink}
                        >
                          Features
                        </Link>
                      </li>
                      <li className="text-white text-base font-light py-3">
                        <Link
                          activeClassName="text-blue-500 font-medium"
                          to={techSpecsLink}
                        >
                          Tech Specs
                        </Link>
                      </li>
                      <li className="text-white text-base font-light py-3">
                        <Link
                          activeClassName="text-blue-500 font-medium"
                          to={faqLink}
                        >
                          F.A.Q.
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </nav>
      <nav className="h-[56px] w-full bg-gray-800 hidden md:block sticky top-0 z-20">
        <div className="md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg flex flex-row items-center mx-auto h-[56px] justify-between">
          <h3 className=" text-lg font-medium text-white">
            <Link to={featuresLink}>{productName}</Link>
          </h3>
          <ul className = "flex flex-row space-x-4 items-center">
            <li>
              <p className = "text-white text-sm hover:text-medium hover:text-blue-400"><Link activeClassName = "text-blue-400 font-medium" to = {featuresLink}>Features</Link></p>
            </li>
            <li>
              <p className = "text-white text-sm hover:text-medium hover:text-blue-400"><Link activeClassName = "text-blue-400 font-medium" to = {techSpecsLink}>Tech Specs</Link></p>
            </li>
            <li>
              <p className = "text-white text-sm hover:text-medium hover:text-blue-400"><Link activeClassName = "text-blue-400 font-medium" to = {faqLink}>FAQ</Link></p>
            </li>
            <li>
              {/* <Link to={buyLink}>
                <button className="py-2 px-4 rounded-full bg-blue-600 text-xs text-white">
                  Buy
                </button>
              </Link> */}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default ProductNavigation;
